<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin :searchState="searchState"></headerMin>
    </div>
    <div class="doctorDetails_content wrap">
      <div class="dc_indexes">
        <span @click="$router.push('/')">首页</span> >
        <span @click="$router.push('/pharmacist/index')">药师咨询</span>
        >
        {{ this.UserArr.realname }}
      </div>
      <!-- 医生信息 -->
      <div class="dc_doctorNews red">
        <div class="userImg">
          <img v-if="!isDefalutImg" :src="UserArr.avatar" @error="isDefalutImg = !isDefalutImg" />
          <img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" />
        </div>
        <div class="dc_usertxt red">
          <div class="txt">
            <span>{{UserArr.realname}}</span> 药师
          </div>
          <div class="txtMsg">简介: {{UserArr.jianjie ? UserArr.jianjie :"无"}}</div>
          <div class="wait_num">{{ waiting_num == -1 ? 0 : waiting_num }}人排队</div>
        </div>
      </div>
      <!-- 问诊方式 -->
      <div class="dc_mode">
        <div class="m_title">
          <span>服务方式</span>请根据您的需要选择适合您的在线问诊方式
        </div>
        <div class="m_tabs">
          <div @click="GoWait()" class="m_tab1">
            <i></i>
            <img src="@/assets/image/Persondetails_picture_img@2x.png" alt />
            <div class="fr">
              <p class="p1">图文咨询</p>
              <p class="p2">免费</p>
              <p class="p3">通过图片、文字进行咨询</p>
            </div>
          </div>
          <div @click="GoWait('video')" class="m_tab1">
            <img src="@/assets/image/Persondetails_video_img@2x.png" alt />
            <div class="fr">
              <p class="p1">视频咨询</p>
              <p class="p2">免费</p>
              <p class="p3">通过视频进行咨询</p>
            </div>
          </div>
          <div @click="GoShenfang" class="shenfang">
            <img src="@/assets/image/news_Settledin_icon@2x.png" alt />
            <div class="fr">
              <p class="p1">药师审方</p>
              <p class="p2">免费</p>
              <p class="p3">对你的审方直接审核</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dc_assess">
        <div class="a_title">
          <span>咨询记录</span>点击查看你与该药师过往的咨询记录
        </div>
        <div v-if="$token &&  consultList.length != 0" class="a_list">
          <div class="record" v-for="(v, i) in consultList" :key="i">
            {{ v.add_time }}
            <span @click="getChatInfo(v)">点击查看</span>
          </div>
        </div>
        <div v-else class="a_record">暂无更多咨询记录</div>
        <div class="mask" @mousewheel="rollHandler" v-if="showRecord" ref="mask">
          <div class="record">
            <div class="title">
              <span>与{{ currentRecord.doctor_realname }}的聊天记录</span>
              <img
                @click="showRecord = false"
                src="@/assets/image/Persondetails_Popup_Close_icon@2x.png"
                alt
              />
              <span class="time">{{ currentRecord.add_time.split(" ")[0] }}</span>
            </div>
            <div class="content">
              <div v-if="chatRecord.length != 0">
                <div class="box" v-for="(v, i) in chatRecord" :key="i">
                  <p>
                    <span>{{ v.sender_type == 0 ? '我' : v.sender_name}}</span>
                    {{ currentRecord.add_time.split(" ")[0] }}
                    {{ moment(v.add_time * 1000).format('h:mm:ss') }}
                  </p>
                  <div v-if="v.content != ''">{{v.content}}</div>
                  <div v-if="v.img_url">
                    <img class="img_url" :src="v.img_url" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";
import { getinfo } from "@/request/index"; // 药师详情
import { tainjiawenzhen } from "@/request/index"; // 添加问诊
import { getChatList } from "@/request/user";
import { getInquiryChatList } from "@/request/index";

import moment from "moment";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      searchState: 0,
      search: "",
      state: 2, // 步骤条
      UserArr: {}, // 医生 详情
      id: "",
      uid: "",
      isDefalutImg: false,
      consultList: [],
      chatRecord: {},
      showRecord: false,
      currentRecord: {}
    };
  },
  created() {
    let ids = this.$route.query.id;
    this.id = this.$route.query.id;
    this.uid = this.$route.query.uid;
    this.waiting_num = this.$route.query.waiting_num;
    this.$user.uid = this.uid;
    this.start(ids);
    this.moment = moment;
  },
  methods: {
    tabImg() {},
    start(id) {
      getinfo({
        id: id,
        token: this.$token
      }).then(res => {
        this.waiting_num = res.data.waiting_num;
        this.UserArr = res.data;
        this.isFollow = this.UserArr.is_fav;
      });

      getInquiryChatList({
        token: this.$token,
        doctor_uid: this.uid
      }).then(res => {
        if (res.code == 1) {
          this.consultList = res.data.data;
        }
      });
    },
    getChatInfo(v) {
      this.showRecord = true;
      this.currentRecord = v;
      getChatList({
        token: this.$token,
        inquiry_doctor_id: v.id
      }).then(res => {
        if (res.code == 1) {
          console.log(res.data.data);
          this.chatRecord = res.data.data;
        }
      });
    },
    GoWait(v) {
      let chat_type;
      if (v === "video") {
        chat_type = 1;
      } else {
        chat_type = 0;
      }
      tainjiawenzhen({
        token: this.$user.token,
        doctor_uid: this.uid,
        chat_type
      }).then(res => {
        if (res.code == 0) {
          this.$message.error(res.msg);
          return;
        }
        if (res.code == 400) {
          this.$message({
            type: "error",
            message: res.msg
          });
          return;
        }
        let data = res.data;
        this.$router.push({
          path: `/pharmacist/wait?id=${this.id}&uid=${this.uid}&inquiry_doctor_id=${data.inquiry_doctor_id}&inquiry_id=${data.inquiry_id}`
        });
      });
    },
    GoAllGoods() {
      this.$router.replace({ path: "/allGoods" });
    },
    GoShenfang() {
      this.$router.push({ path: `/shenfang/upimg?uid=${this.uid}` });
    },
    rollHandler(e) {
      if (e.target == this.$refs.mask) {
        e.preventDefault();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../doctor/doctor.less";

@import "./pharmacist.less";
</style>